<template>
  <div class="view pa24">
    <div>
      员工姓名：
      <el-input class="w220 mr20" v-model="name" placeholder="请输入员工姓名" />
      排序：
      <el-select class="mr20" v-model="sort" clearable>
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
          {{ item.name }}
        </el-option>
      </el-select>
      <el-button type="primary" @click="queryStaffData">查询</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="name" align="center" label="员工姓名" />
        <el-table-column prop="department" align="center" label="职务" />
        <el-table-column
          prop="shareNum"
          align="center"
          label="分享文章数"
        />
        <el-table-column prop="essayNum" align="center" label="获客数" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="$router.push(`/staffDataDetail?userId=${scope.row.userId}&name=${scope.row.name}`)">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryStaffData } from "@/api/articleManagement";
export default {
  name: "employeeData",
  components: {
    commonTable,
  },
  data() {
    return {
      options: [
        { id: 1, name: "分享文章数由高到低" },
        { id: 2, name: "分享文章数由低到高" },
        { id: 3, name: "分享次数由高到低" },
        { id: 4, name: "分享次数由低到高" },
        { id: 5, name: "获客数由高到低" },
        { id: 6, name: "获客数由低到高" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      sort: "",
      name: "",
    };
  },
  created(){
    this.queryStaffData();
  },
  methods: {
    async queryStaffData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        name: this.name,
        sort: this.sort,
      };
      try {
        this.loading = true;
        const result = await queryStaffData(data);
        this.loading = false;
        console.log(result);
        const { total, list } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryStaffData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryStaffData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>